<template>

	<div class="wrap p-sticker-guide">
		<!-- header -->
		<header class="header-step">
			<div class="head-left"></div>
			<div class="head-center">
				<div class="head-tit">
					<span class="page-tit">스티커는 언제 받을 수 있어요?</span>
				</div>
			</div>
			<div class="head-right">
				<button type="button" class="btn-close" @click="closeApp"><span class="blind">닫기</span></button>
			</div>
		</header>
		<!-- container -->
		<div class="container">
			<div class="content">
				<div class="img-wrap">
					<img src="/assets/images/mypage/img_sticker_guide.png" alt="">
				</div>
				<div class="guide-info">
					<p class="guide-text">
						<span>칭찬 스티커는 Book 별 최대 19개까지 받을 수 있어요.</span><br>
						(영상1회 시청 시 스티커 1개 지급, 복습 시 최대 2개까지 지급)
					</p>
					<ul class="sticker-list">
						<li>
							<span>최대 3개</span>
							<strong>Video</strong>
						</li>
						<li>
							<span>최대 3개</span>
							<strong>E-Book</strong>
						</li>
						<li>
							<span>최대 3개</span>
							<strong>Week1</strong>
						</li>
						<li>
							<span>최대 3개</span>
							<strong>Week2</strong>
						</li>
						<li>
							<span>최대 3개</span>
							<strong>Week3</strong>
						</li>
						<li>
							<span>최대 3개</span>
							<strong>Week4</strong>
						</li>
						<li>
							<span>최대 1개</span>
							<strong>Book Test</strong>
						</li>
					</ul>
				</div>
			</div>
		</div>
	</div>

</template>

<script>
export default {
	name: "stickerMsg",
	methods: {

		closeApp () {
			this.appClose({
				reload: false
			})
		}

	}
}
</script>

<style scoped>

</style>